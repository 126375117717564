import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} textColor="white" indicatorColor="primary"  aria-label="basic tabs example" >
          <Tab label="Accessoire" {...a11yProps(0)}  />
          <Tab label="Qualité" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <>
        <img src="Assets/img/details.jpg"  alt="details" style={{'width':'100%' ,'background':'white'}}/>
        </>
      </TabPanel>
      <TabPanel value={value} index={1}>
              <>
              <p style={{'color':'white','fontSize':'16px'}}> Votre robot vous simplifie la vie au quotidien, et cuisiner n'est plus une corvée ! Les recettes intégrées à votre appareil COCTUM ainsi que toutes celles que vous souhaiteriez télécharger via une connexion Wi-Fi, seront détaillées étape par étape. La peine de préparer vos repas est terminée. Vous êtes guidé pas à pas par COCTUM. Une fois que vous aurez sélectionné la recette de votre choix, vous aurez automatiquement sur votre écran le temps de préparation, le nombre de personnes et la liste des ingrédients pour vos courses. Lancez votre recette, et le tour est joué ! Vous avez également la possibilité de visualiser la recette dans son intégralité pour un aperçu.</p>
              </>
      </TabPanel>
    </Box>
  );
}
