
import React from 'react' ;
import "../Header.css";



export default function Header() {


    return(
        <>
         <div className="cat_top_wrapper cat_home_top_menu">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="cat_heading_wrapper">
                            <div className="cat_logo">
                                <a href="https://mycoctum.com/"> <img src="https://mycoctum.com/images/logoo.png" style={{'display': 'block','width': '40%'}} alt=" " /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 padding-right padding-left" style={{'position': 'absolute','left': '550px'}}>
                        <div className="cat_menu_wrapper_2">
                            <nav>
                                <div className="menu_toggle"><i className="flaticon-food-3"></i>
                                    <span className="menu_name">menu</span>
                                </div>
                                <div className="collapse navbar-collapse cat_menu">
                                    <div className="close_btn"><i className="flaticon-tool-1"></i></div>
                                    <div className="menu_overlay"></div>
                                    <ul className="nav navbar-nav" style={{'display':'flex','flexDirection':'row'}}>
                                        <li><a className="active" href="https://mycoctum.com/">Accueil</a>
                                        </li>
                                        <li><a href="https://mycoctum.com/decouverte">Découverte </a>

                                        </li>
                                        <li><a href="https://mycoctum.com/experience">Expérience</a>
                                        </li>
                                         <li><a href="https://mycoctum.com/faq">FAQ</a>
                                        </li>
                                        <li><a href="/">Acheter</a>
                                        </li>
                                        
                                        <li><a href="https://mycoctum.com/contact">Contact</a>
                                        </li>
                                        {/* <li className="dropdown">
                                            <a href=" " className="dropdown-toggle" data-toggle="dropdown" role="button"
                                                aria-expanded="false"><i className="fa-solid fa-globe" width="20"
                                                    height="10" href="#" style={{'marginRight': '10px'}}></i><span
                                                    className="caret"></span></a>
                                            <ul className="dropdown-menu" role="menu">
                                                <li><a href="../Coctum_Français/index.html"><img src="/Assets/images/lang/frensh.png" width="20" height="10" href=" "
                                                            style={{'marginRight': '10px'}}  alt=" " />Frensh</a></li>
                                                <li><a href="../Coctum_Anglais/index.html"><img src="/Assets/images/lang/uk.png" width="20" height="10" href=" "
                                                            style={{'marginRight': '10px'}} />English</a></li>
                                                <li><a><img src="/Assets/images/lang/saudie.png" width="20" height="10" href=" "
                                                            style={{'marginRight': '10px'}} />Arabic</a></li>
                                            </ul>
                                        </li> */}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}