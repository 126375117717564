import React, { Component } from 'react';
import axios from 'axios';
 
class ListCmd extends Component {
  state = {
    datas: []
  }
  componentDidMount() {
    axios.get('http://localhost/coctum/GetData.php').then(res => 
        {
        this.setState({datas: res.data});
        });
  }
  render() {
  
    return (
       <div className="container" style={{'padding': '15% 0%'}}>
        <div className="col-xs-8" >

        <table className="table table-dark">
          <thead className="thead-light ">
            <th className='text-light'>N° Commande</th>
            <th className='text-light'>Nom</th>
            <th className='text-light'>Prenom</th>
            <th className='text-light'>Adresse</th>
            <th className='text-light'>Email</th>
            <th className='text-light'>Telephone</th>
            <th className='text-light'>Code Promo</th>
            <th className='text-light'>Status</th>
          </thead>
          <tbody>
          {this.state.datas.map((rs, index) => (
            <tr key={index}>
                <td>{rs.id}</td>
              <td>{rs.nom}</td>
              <td>{rs.prenom}</td>
              <td>{rs.adresse}</td>
              <td>{rs.Email}</td>
              <td>{rs.tel}</td>
              <td>{rs.codePromo}</td>
              <td>validé</td>
            </tr>
            ))}
          </tbody>
        </table>
        </div>
       </div>
    );
  }
}
export default ListCmd;