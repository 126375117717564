
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import Product from './components/Product';
import Form from './components/Form';
import FloatingWhatsApp from 'react-floating-whatsapp' ;
import Header from './components/header/Header';
import List from './components/List';

function App() {
  return (
    <div className="App">

      <Header />
      <Switch>
        <Route exact path="/"><Product /></Route>
        <Route path="/formulaire"><Form /></Route>
        <Route path="/list"><List /></Route>
      </Switch>
      <FloatingWhatsApp phoneNumber={"+212672144499"} className={"flow_whatsapp"} statusMessage={"online"} accountName="COCTUM" chatMessage={"Bienvenue chez COCTUM ! Comment puis-je vous aider?"}/>

    </div>
  );
}

export default App;
