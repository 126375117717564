import { Component } from 'react';
import ImageGallery from 'react-image-gallery';


const images = [
    {
        original: '/Assets/img/coctum3.png',
        thumbnail: '/Assets/img/coctum3.png',
        },
  {
    original: '/Assets/img/6.png',
    thumbnail: '/Assets/img/6.png',
  },
  
  {
    original: '/Assets/img/4.png',
    thumbnail: '/Assets/img/4.png',
  }
  
];

export default class MyGallery extends Component {
  render() {
    return <ImageGallery items={images} />;

  }
}