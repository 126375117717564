import React from 'react' ;
import axios from 'axios'
import Swal from 'sweetalert2' ;
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';






class MyForm extends React.Component {
    constructor(props)
    {
      super(props);
      this.addFormData = this.addFormData.bind(this);
    }
  //Form Submission

  addFormData(evt)
    {
      evt.preventDefault();
      const fd = new FormData();
      fd.append('myNom', this.refs.myNom.value);
      fd.append('myPrenom', this.refs.myPrenom.value);
      fd.append('myAdresse', this.refs.myAdresse.value);
      fd.append('myEmail', this.refs.myEmail.value);
      fd.append('myTel', this.refs.myTel.value);
      fd.append('myCode', this.refs.myCode.value);
      axios.post('http://localhost/coctum/SendData.php', fd
      ).then(
      {

    }
    
    );
    this.myFormRef.reset();
    Swal.fire({
        title: 'COCTUM',
        text: 'Votre commande a bien été envoyée',
        type: 'success',
      });
      <Redirect to='/' />
      window.location.href = '/'
    }

    render(){
        return(
        <>
          <div className='container'>
              <form style={{'margin':'auto' , 'padding':'20% 10%'}} ref={(el) => this.myFormRef = el} >
              <br />
              <div>
                  <label className='text-light animate__animated animate__fadeInDown'><h6 className='text-light'>Utilisez le formulaire ci-dessous pour commander votre produit COCTUM .</h6> </label>
              </div>
              <div>
            {/* <Link to='/list'>List commandes</Link> */}
          </div>
              <br />
                <div className="form-row animate__animated animate__fadeInDown">
                    <div className="form-group col-md-5 text-light">
                    <label htmlFor="inputEmail4">Nom</label>
                    <input type="text" className="form-control" id="Nom" name="MyNom"  placeholder="Nom" ref="myNom" required/>
                    </div>

                    <div className="form-group col-md-5 text-light">
                    <label htmlFor="inputEmail4">Prenom</label>
                    <input type="text" className="form-control" id="Prenom" name="MyPrenom" placeholder="Prenom" ref="myPrenom" required/>
                    </div>
                    <div className="form-group col-md-5 text-light">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text" className="form-control" id="adresse"name="myAdresse" placeholder="Address postale" ref="myAdresse" required/>
                    </div>
                    <div className="form-group col-md-4 text-light">
                        <label htmlFor="inputAddress2">Email</label>
                        <input type="email" className="form-control" id="email" name="myEmail" placeholder="Email" ref="myEmail" required/>
                    </div>
                    <div className="form-group col-md-4 text-light">
                    <label htmlFor="inputCity">Téléphone</label>
                    <input type="text" className="form-control" id="tel" name="myTel" placeholder="Téléphone" ref="myTel" required/>
                    </div>
                    <div className="form-group col-md-2 text-light">
                    <label htmlFor="inputZip">Code promo</label>
                    <input type="text" className="form-control" id="code" name="myCode" placeholder="code promo" ref="myCode" required/>
                    </div> 
                    <br/>
                    <div className="form-group col-md-8 text-light">
                    <button type="submit" className="btn btn-primary" onClick={this.addFormData}>Commander</button>
                    </div>
                </div>
                </form>
          </div>
          
        </>
        );
    }
}
export default MyForm ;